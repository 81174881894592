@import 'assets/scss/Variables.scss';

.sidebar {
  position: relative;

  &-menu {
    border-left: 4px solid $primary-color !important;
    border-top: none !important;
    border-radius: 0 !important;
    top: 1px;
    width: 115px !important;
    overflow-x: hidden;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 5px !important;
    }
  }
}
