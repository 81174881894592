@import 'assets/scss/Variables.scss';

.form-dropdown {
  width: 100%;
  display: flex;
  flex-direction: column;
  background: $primary-bg-color;
  border: 2px solid #d7d7d7;

  .ui.selection.active.dropdown .menu {
    border-radius: 0 !important;
    margin-top: 5px;
  }

  &__label {
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    color: $text-color;
    opacity: 0.7;
    margin-left: 9px;
  }

  &__items {
    border: none !important;
  }

  .icon {
    border: none;
  }
}
