@import './Mixins.scss';

.ui {
  &.button {
    border-radius: 0;
    font-family: 'Henderson Sans', sans-serif !important;
    font-weight: 600 !important;
    letter-spacing: 0.1em;
    line-height: 18px;

    &.green {
      background-color: $primary-color !important;
      border: solid 1px $primary-color !important;
      color: #fefcfe !important;

      &:hover {
        color: $primary-bg-color;
        background-color: #023c21 !important;
      }
    }

    &--download,
    &--upload-users,
    &--add-new-user {
      border-radius: 0 !important;
      font-weight: 300 !important;
      padding: 11px 21px;

      @include icon-style;

      svg,
      i {
        margin-right: 10px !important;
      }
    }

    &--only-icon {
      border-radius: 0 !important;
      font-weight: 300 !important;
      padding: 11px 21px;
      height: 40px;
      margin-right: 10px;

      @include icon-style;

      svg,
      i {
        margin-right: 0 !important;
      }
    }

    &--add-new-user {
      align-items: center;
      display: flex;
    }

    &--save-to-bucket {
      border-radius: 0 !important;
      background: #fff !important;
      padding: 0;
      height: 40px;

      .bucket-dropdown-trigger {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 185px;
        padding: 10px 0;
        background-color: $light-red-color;
        border: solid 1px $light-red-color;

        i {
          margin-right: 10px !important;
          margin-top: -2px !important;

          img {
            width: 16px;
            height: 15px;
          }
        }
      }

      &.active,
      &:hover {
        .bucket-dropdown-trigger {
          background-color: #fff;
          border: 1px solid rgb(25 122 86 / 20%);

          svg > path {
            fill: $primary-color;
          }
        }
      }

      div.save-to-bucket-outer-menu {
        border-radius: 0 !important;
        background: #fff;
        box-shadow: 0 0 25px rgb(0 0 0 / 20%) !important;
        z-index: 8;
        width: 115%;

        &__item {
          padding: 10px;
          padding-bottom: 0;

          &:not(.floating) {
            box-shadow: none !important;
          }

          .item {
            font-family: 'Henderson Sans', sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 15px;
            letter-spacing: -0.03em;
            color: $text-color;
            word-wrap: break-word;
            word-break: break-all;
            white-space: normal;

            &:hover {
              font-weight: 600;
              border-radius: 4px;
              background-color: $light-red-color;
            }

            .records-count {
              margin-left: 5px;
              font-weight: normal !important;

              span {
                font-weight: 600 !important;
              }
            }
          }
        }

        .divider {
          border-top-color: #d7d7d7;
        }

        .input {
          margin: 5px !important;

          .btn-cancel {
            width: 30px;
            padding-left: 8px;
          }

          .btn-save {
            width: 30px;
            background-color: $primary-color;
            padding-left: 8px;

            &:hover {
              background-color: #023c21 !important;
            }
          }
        }

        .header {
          font-style: normal;
          font-weight: 400;
          font-size: 13px;
          line-height: 15px;
          letter-spacing: -0.03em;
          color: $primary-color;
          text-transform: none;
          cursor: pointer;
          height: 32px;
          padding: 6px;
          margin: 5px 0;
          margin-left: 20px;
        }
      }
    }
  }
}
