/*
Css for Header Component
*/
@import 'assets/scss/Variables.scss';

.application-header {
  display: flex;
  flex-direction: column;
  position: sticky;
  top: 0;
  z-index: 10;
  background-image: url('../../assets/images/bg-img.svg');
  background-size: cover;

  &__header-ui {
    position: sticky;
    left: 0;
    top: 0;
    height: 94px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 20;

    &__img {
      display: flex;
      justify-content: center;
      width: 200px;

      &__app-logo {
        cursor: pointer;
      }
    }

    &__main {
      align-items: center;
      display: flex;
      flex-grow: 1;
      justify-content: space-evenly;

      &__info-img {
        height: 20px;
      }

      &__text {
        display: flex;
        height: 55px;
        background: $primary-bg-color;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        &__search-img {
          margin-left: 25px;
          height: 21px;
          width: 21px;
          align-self: center;
        }

        &__go-btn {
          align-items: center;
          display: flex !important;
          justify-content: center;
          width: 48px;
          height: 48px;
          background: $primary-bg-color !important;
          border: 1px solid $primary-color !important;
          margin-right: 15px !important;
          border-radius: 0 !important;
        }

        &__go-text {
          font-family: 'Henderson Sans', sans-serif;
          color: $primary-color;
        }
      }

      &__filter {
        width: 142px;
        height: 60px;
        border-radius: 0 !important;
        background: $primary-bg-color !important;
        margin-left: 10px !important;

        &__filter-img {
          display: flex;
          justify-content: space-around;
          align-items: center;
        }

        &__filter-text {
          font-family: 'Henderson Sans', sans-serif;
          font-style: normal;
          font-weight: 600;
          font-size: 17px;
          line-height: 18px;
          color: $primary-color;
        }

        &__filter-text-on {
          font-family: 'Henderson Sans', sans-serif;
          font-style: normal;
          font-weight: 600;
          font-size: 17px;
          line-height: 18px;
          color: $primary-bg-color;
        }
      }

      &__filter-on {
        width: 142px;
        height: 60px;
        border-radius: 0 !important;
        background: $primary-color !important;
        margin-left: 10px !important;
      }

      &__pipe {
        margin-right: 15px;
      }
    }
  }
}

.popup-style {
  background-color: lightgrey;
  font-size: 14px !important;
}
