@import 'assets/scss/Variables.scss';

.case-details-content {
  background: $primary-bg-color;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 5px 10px 0;
  width: 100%;

  &__right {
    width: 230px;
  }
}
