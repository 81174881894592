@import 'assets/scss/Variables.scss';

.search-container {
  flex-grow: 1;
  height: 100%;
  position: relative;

  .backdrop {
    width: 100%;
    height: 100%;
    padding: 17px;
    position: absolute;
    background-color: #fff;
    overflow: auto;
    pointer-events: none;
    transition: transform 1s;

    .highlights {
      color: transparent;
      font-size: 14px;
      line-height: 1 !important;
      white-space: pre-wrap;
      word-wrap: break-word;

      .operator {
        border-radius: 3px;

        &-and {
          background: #d4df33;
          color: $primary-color;
        }

        &-or {
          background: #c9e7ca;
          color: $primary-color;
        }
      }

      .plain-text {
        background: #fff;
        color: inherit;
      }
    }
  }

  textarea {
    padding: 17px !important;
    font-family: 'Henderson Sans', sans-serif !important;
    font-size: 14px;
    width: 96%;
    height: 100%;
    display: block;
    position: absolute;
    z-index: 2;
    margin: 0;
    border: none !important;
    border-radius: 0;
    color: #444;
    background-color: transparent;
    overflow: auto;
    resize: none;
    transition: transform 1s;

    &:focus-visible {
      outline: none;
    }
  }

  .clear-button {
    position: absolute;
    cursor: pointer;
    left: 96%;
    top: 20px;
  }
}
