@import 'assets/scss/Variables.scss';

.case-contact {
  background: $secondary-bg-color;
  padding: 10px;
  margin-bottom: 15px;

  &__start-date {
    font-size: 13px;
    font-weight: 600;
    color: #197a56;
  }

  &__date {
    font-size: 11px;
    color: #111;
    opacity: 0.7;
  }

  &__button {
    width: 100%;
    background: #e5e5e5 !important;
    border: 1px solid #03522d !important;
    margin-top: 20px !important;
    padding-bottom: 10px !important;
    padding-top: 10px !important;

    &__text {
      color: $primary-color;
      font-size: 9px !important;
    }
  }
}
