/* Variables */

$primary-color: #03522d;
$primary-bg-color: #fff;
$secondary-bg-color: #e5e5e5;
$alert-bg-color: #ffa2a2;
$alert-text-color: #ff4646;
$table-header-text-color: #333;
$text-color: #111;
$grey-bg-color: #e8edee;
$dark-grey-color: #6e6f73;
$red-color: #ff4646;
$light-red-color: #f2f2f2;
$text-highlight-color: #ff0;
$info-bg-color: #cff4fc;
$info-text-color: #065161;
$warn-bg-color: #fff3cd;
$warn-text-color: #664d03;
$error-bg-color: #f8d7da;
$error-text-color: #842029;
$success-bg-color: #d1e7dd;
$success-text-color: #0f5132;
