@import 'assets/scss/Variables.scss';

.case-details-actions-header {
  display: flex;
  flex-direction: row;
  width: 100%;

  &__main {
    align-items: center;
    display: flex;
    background: $primary-bg-color;
    justify-content: space-between;
    margin-right: 10px;
    flex-basis: 100%;
    padding: 2px 5px;

    &__left {
      justify-self: flex-start;
      padding: 0 10px;
      display: flex;

      &__record-details {
        font-style: normal;
        font-weight: 600;
        font-size: 15px;
        color: $text-color;
      }

      &__results {
        font-style: normal;
        font-weight: 600;
        font-size: 15px;
        text-decoration-line: underline;
        color: #197a56;
        cursor: pointer;
      }
    }

    &__right {
      align-items: center;
      display: flex;

      &__records {
        padding: 0 15px;
        font-weight: 600;
        font-size: 14px;
        color: #000;
      }

      &__buttons {
        button {
          background: $primary-bg-color !important;
          border: none !important;
          box-shadow: none !important;
          color: #000 !important;
          font-size: 14px;
        }
      }
    }
  }

  &__download {
    button {
      min-width: 205px;
    }
  }
}
