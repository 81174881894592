@import 'assets/scss/Variables.scss';

#pagination-bar {
  #page-limit {
    height: 34px;
    width: 34px;
    background-color: $light-red-color;
    position: absolute;

    &:hover,
    &.visible {
      background-color: $primary-color;

      i svg {
        path {
          fill: #fefefe;
          stroke: #03522d;
        }
      }
    }

    > i {
      position: absolute;
      transform: translate(50%, 50%);
      margin: 0;
      height: 50%;
      width: 50%;
    }

    .item {
      margin: 5px;

      &.selected {
        margin: 5px;
        border-radius: 4px;
        background-color: $light-red-color;
      }
    }
  }

  #grid-pagination {
    height: 34px;
    border: 0;
    background: none;
    border-radius: 0;
    box-shadow: none;

    > a {
      width: 34px;
      min-width: revert;
      height: 34px;
      border-radius: 0;
      border: 0;
      background-color: $light-red-color !important;
      margin-right: 5px;
      box-sizing: border-box;
      justify-content: center;

      &::before {
        border: 0;
        width: 0;
        background: none;
      }

      &.active {
        background-color: #e6eeea !important;
        border: 1px solid $primary-color;
        color: $primary-color;
        cursor: not-allowed;
      }

      &:not(.disabled):not(.active) {
        &:hover {
          background-color: $primary-color !important;
          color: $light-red-color;
        }
      }
    }
  }
}
