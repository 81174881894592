@import 'assets/scss/Variables.scss';
@import 'assets/scss/Mixins.scss';

.menu-item {
  border-radius: 0 !important;
  color: $primary-color !important;
  display: flex !important;
  flex-direction: column !important;
  justify-content: center;
  align-items: center;
  height: 84px !important;
  width: 100%;

  &__text {
    line-height: normal;
    text-align: center;

    @include text-style(12px, 400);
  }

  &__img {
    height: 20px !important;
    width: 20px !important;
    margin-bottom: 10px !important;
  }

  &.active.item {
    border-radius: 0;
    background: $primary-color !important;
    box-shadow: none;
    color: $primary-bg-color !important;
  }
}
