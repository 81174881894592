.home-cards-layout {
  .card {
    margin: 0 0 20px !important;

    .header {
      overflow: hidden;
      overflow-wrap: break-word;
    }
  }
}
