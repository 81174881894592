.combo-box-container {
  background: #fff;
  border: 2px solid #d7d7d7;
  height: 83px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &__setwidth {
    width: 100%;
    overflow-y: scroll;
  }

  .combo-box {
    &__multi-value {
      border-radius: 20px !important;
      border: 1px solid rgb(91 182 131 / 30%) !important;
    }

    &__control {
      border: 0 !important;

      &--menu-is-open {
        box-shadow: 0 0 0 1px #fff !important;
      }

      &--is-focused {
        box-shadow: 0 0 0 1px #fff !important;
      }
    }

    &__indicators {
      display: none !important;
    }
  }

  /* stylelint-disable selector-class-pattern */
  .combo-box-menu-container {
    position: relative;

    .combo-box__menu-portal {
      width: 200px;
      border-radius: 0;
      margin-top: 0;
      top: 0 !important;
      left: 0 !important;
    }
  }
  /* stylelint-enable selector-class-pattern */

  &__title {
    font-family: 'Henderson Sans', sans-serif;
    font-style: normal;
    font-size: 11px;
    color: #111;
    opacity: 0.7;
    margin-left: 9px;
    margin-top: 4px;
  }
}
