@import 'assets/scss/Variables.scss';
@import 'assets/scss/Mixins.scss';

.mylists-page {
  height: 100%;
  padding: 15px;
  padding-top: 0;

  &__header {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 15px 0;

    &__buckets {
      &__names {
        div#{&}__label {
          font-weight: 600;
          font-size: 15px;
          color: $text-color;
          line-height: 19px;
          letter-spacing: -0.03em;
          background-color: $grey-bg-color;
          padding: 6px 8px;
          border-radius: 0;

          .icon {
            margin: 0;
            margin-left: 10px;
            cursor: pointer;
          }
        }
      }

      &__total {
        font-weight: 300;
        font-size: 12px;
        color: $dark-grey-color;
        margin-left: 5px;
      }
    }

    &__actions {
      display: flex;

      &__action {
        margin-right: 10px !important;

        :last-child {
          margin-right: 0 !important;
        }
      }

      .download-result {
        height: 40px;
      }
    }
  }

  &__main {
    height: calc(100% - 95px);

    &--no-records {
      align-items: center !important;
      display: flex !important;
      justify-content: center;
    }

    @include sortable-table('#my-lists-table');
  }
}
