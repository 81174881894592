@import 'assets/scss/Variables.scss';

.header-dashboard {
  position: sticky;
  left: 0;
  top: 0;
  height: 70px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: -1;

  .five-column {
    display: flex;

    .five-column-div {
      flex: 2;
      display: flex;
      padding: 10px;
    }

    .space {
      margin-left: -38px;
    }
  }

  .download {
    margin-right: 35px;
    border: 1px solid $primary-bg-color !important;
    border-radius: 0 !important;
    background-color: transparent !important;

    .download-img {
      display: flex;

      .download-text {
        font-size: 13px;
        margin-left: 8px;
        color: $primary-bg-color;
      }
    }
  }

  .stat {
    flex-direction: column;
    margin-left: 10px;
    display: flex;

    .stat-num {
      width: 69px;
      height: 23px;
      left: 55px;
      top: 0;
      font-family: 'Henderson Sans', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 23px;
      letter-spacing: -0.03em;
      color: $grey-bg-color;
    }

    .stat-text {
      width: 180px;
      height: 15px;
      left: 55px;
      top: 28px;
      font-family: 'Henderson Sans', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 15px;
      letter-spacing: -0.03em;
      color: $grey-bg-color;
    }
  }
}

.download-btn-wrap {
  display: flex;
  align-items: end;

  a {
    color: #fff;
    cursor: pointer;
    text-decoration: underline;
    margin-right: 15px;
    font-size: 16px;
  }
}
