@import 'assets/scss/Mixins.scss';
@import 'assets/scss/Variables.scss';

.buckets-dropdown {
  .menu.transition.visible {
    position: relative !important;
    z-index: 7;
    max-width: 250px;
    min-width: 100px;
    left: 0 !important;
    overflow-y: auto;
    padding: 10px;
    background: #fff;
    box-shadow: 0 0 25px rgb(0 0 0 / 20%);

    > .item {
      padding: 0 !important;
      margin: 2px;

      &.active {
        background: $light-red-color;
        border-radius: 4px;

        .checkbox > label {
          font-weight: 700;
        }
      }

      .item-content {
        min-height: 40px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .checkbox {
          padding: 11px 5px;
          font-weight: 400 !important;
          font-size: 12px !important;
          min-width: 80px;
          max-width: 150px;

          > label {
            line-height: 14px;
            color: $text-color;

            &::before {
              width: 14px;
              height: 14px;
            }

            &::after {
              width: 14px;
              height: 14px;
              background-color: $text-color;
              color: $primary-bg-color;
              font-size: 9px;
            }
          }
        }

        .item-action {
          margin: 0 10px;
          padding-right: 10px;
          visibility: hidden;

          &__edit,
          &__delete {
            cursor: pointer;
            height: 14px;
            width: 14px;
            position: relative;
            top: 3px;
          }

          &__edit {
            margin-right: 13px;
          }
        }
      }

      &:hover .item-action {
        visibility: visible;
      }

      div.new-bucket {
        margin: 5px !important;
        height: 26px;

        input {
          max-width: 135px;
        }

        .btn-cancel {
          width: 30px;
          padding-left: 8px;
        }

        .btn-save {
          width: 30px;
          background-color: $primary-color;
          padding-left: 8px;

          &:hover {
            background-color: #023c21 !important;
          }
        }
      }
    }

    .message {
      white-space: break-spaces;
    }

    &::after {
      display: none;
    }
  }

  &__wrapper {
    position: absolute;
    left: 110px !important;
    z-index: 1010;

    &::after {
      display: block;
      position: absolute;
      pointer-events: none;
      content: '';
      visibility: visible;
      transform: rotate(45deg);
      width: 0.5em;
      height: 0.5em;
      box-shadow: -1px 0 0 0 rgb(34 36 38 / 15%);
      background: #fff;
      z-index: 7;
      top: 30px;
      left: 10px;
    }
  }
}
