@import 'assets/scss/Variables.scss';
@import 'assets/scss/Mixins.scss';

.modal-alert {
  @include height-width-bg(
    248px,
    500px !important,
    $primary-bg-color !important
  );

  font-size: 15px;

  &__header {
    &__icon {
      margin-right: 10px;
      top: 3px;
      position: relative;

      img {
        width: 18px;
        height: 16px;
      }
    }

    &__text {
      @include text-style(18px, 700);

      line-height: 19px;
      color: $text-color;
      font-size: 15px;
    }

    &__close {
      float: right;
      cursor: pointer;
    }
  }

  &__content {
    &__text {
      padding: 20px;
      padding-top: 30px;
      padding-bottom: 5px;
      text-align: center;
      font-size: 15px;
    }
  }

  &__footer {
    background: $primary-bg-color !important;
    border-top: 0 !important;
    text-align: center !important;
    padding-bottom: 40px !important;

    Button#{&}__submit {
      @include height-width-bg(48px, 137px, $primary-color);
      @include icon-style(5px !important, 18px, 18px);

      border-radius: 8px !important;
      padding-right: 0 !important;
      padding-left: 10px !important;
      font-weight: 300 !important;

      i {
        margin-right: 13px !important;
      }
    }

    Button#{&}__cancel {
      @include height-width-bg(48px, 123px, $primary-bg-color !important);
      @include icon-style(3px !important, 12px, 12px);

      border: 2px solid $primary-color !important;
      border-radius: 8px !important;
      box-sizing: border-box;
      color: $primary-color !important;
      padding-right: 0 !important;
      padding-left: 0 !important;
      font-weight: 300 !important;

      i {
        background: unset;
      }
    }
  }
}
