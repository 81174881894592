@import 'assets/scss/Variables.scss';

.home {
  background: $secondary-bg-color;
  height: 100%;
  padding: 15px;
  font-size: 3rem;

  .ui {
    &.menu {
      .item {
        &.active {
          background-color: $primary-color !important;
          color: #fff !important;
        }

        :hover {
          background-color: $primary-color !important;
          color: #fff !important;
        }
      }
    }

    &.card {
      border-radius: 0 !important;
      box-shadow: 0 4px 14px rgb(0 0 0 / 8%);
      padding: 20px 15px;
      width: 100%;

      .description {
        font-size: 14px;
        font-weight: 600;
        line-height: 18px;
      }

      .header {
        font-family: 'IBM Plex Serif', serif;
        font-size: 25px;
        font-weight: 600;
        height: 60px;
        line-height: 28px;
      }

      .meta {
        color: #000;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 15px;
        margin-top: 10px;
      }

      a.view-records {
        color: $primary-color;
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: 15px;
        margin-top: 10px;
        text-decoration-line: underline;
      }
    }
  }
}
