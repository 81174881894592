@import 'assets/scss/Variables.scss';

.about-page {
  width: 100%;
  height: 100%;
  padding: 40px;

  &__hero-container {
    height: 40%;

    &__header {
      font-family: 'Henderson Sans', sans-serif;
      font-size: 15px !important;
      font-weight: 600;
      line-height: 19px;
      margin-bottom: 14px;
      letter-spacing: -0.03em;
      color: $text-color;
    }

    &__img {
      height: 80% !important;
      width: 100% !important;
    }
  }

  &__content {
    margin-top: 20px;

    &__description {
      font-weight: 400;
      font-size: 15px;
      line-height: 19px;
      color: #000;
      margin-top: 15px;
      opacity: 0.7;
    }
  }
}
