@import 'assets/scss/Variables.scss';

.case-team {
  background: $secondary-bg-color;
  padding: 10px;
  position: relative;

  &__logo {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    &__text {
      font-weight: 600;
      font-size: 15px;
      color: $text-color;
      margin-left: 14px;
    }
  }

  .person {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    margin: 5px 0;

    .round-img {
      border-radius: 50%;
      height: 40px;
      width: 40px;
    }
  }

  .person-details {
    display: flex;
    flex-direction: column;
    padding-left: 10px;
  }

  &__second-block {
    display: flex;
    justify-content: center;
    align-items: flex-start;

    &__segment {
      display: flex;
      flex-basis: 50%;
      flex-direction: column;
    }

    &__segment:last-of-type {
      border-left: solid 2px #a5a5a5;
      padding-left: 5px;
    }

    .upper-text {
      font-weight: 600;
      font-size: 12px;
      line-height: 13px;
      color: #197a56;
    }

    .lower-text {
      font-size: 11px;
      line-height: 13px;
      color: $text-color;
      opacity: 0.7;
    }
  }

  .teams {
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
  }
}

::-webkit-scrollbar {
  width: 3px !important;
}
