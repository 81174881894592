@import 'assets/scss/Mixins.scss';
@import 'assets/scss/Variables.scss';

.modal-new {
  @include height-width-bg(
    463px,
    800px !important,
    $primary-bg-color !important
  );

  &__header {
    border-bottom: 0 !important;

    &__upload-text {
      @include text-style(18px, 700);

      line-height: 19px;
      color: $text-color;
    }
  }

  &__dropbox {
    @include height-width-bg(
      300px,
      740px,
      rgba(192, 185, 185, 0.2392) !important
    );

    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border: 1px dashed #000;
    transition: border 0.24s ease-in-out;
    justify-content: center;
    margin-left: 10px;

    &__drop-text {
      @include text-style(20px, 400);

      padding-top: 30px;
      color: #111;
    }

    &__or-text {
      @include text-style(19px, 500);

      padding-bottom: 20px;
      padding-top: 5px;
      color: $text-color;
    }

    #upload-btn {
      @include height-width-bg(48px, 171px, $primary-color !important);
      @include text-style(16px, 300 !important);
      @include icon-style(3px !important);

      padding-right: 0 !important;
      padding-left: 0 !important;
    }
  }

  &__file-accept {
    color: $primary-color;
  }

  &__file-reject {
    color: $alert-text-color;
  }

  &__footer {
    background: $primary-bg-color !important;
    border-top: 0 !important;

    #cancel-btn {
      @include height-width-bg(48px, 123px, $primary-bg-color !important);
      @include icon-style(3px !important);

      border: 2px solid $primary-color !important;
      box-sizing: border-box;
      color: $primary-color !important;
      border-radius: unset !important;
      padding-right: 0 !important;
      padding-left: 0 !important;
      font-weight: 300 !important;

      i {
        background: unset;
      }
    }

    &__submit {
      @include height-width-bg(48px, 130px, $primary-color !important);

      border-radius: unset !important;
      padding: 11px 21px !important;
      font-weight: 300 !important;
    }
  }
}
