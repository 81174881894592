.landing-container {
  align-items: center;
  background-image: linear-gradient(rgb(0 0 0 / 50%), rgb(0 0 0 / 50%)),
    url('../../assets/images/landing_background.png');
  background-size: cover;
  display: flex !important;
  height: auto;
  justify-content: center;
  min-height: 100vh !important;
  width: 100vw !important;

  &__content {
    width: 60%;

    &__header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 20px;
    }

    &__body {
      border-bottom: solid 1px #fefcfa;
      border-top: solid 1px #fefcfa;
      color: #fff;
      font-size: 15px;
      line-height: 19px;
      padding: 30px 0;

      &__heading {
        color: #fff !important;
        font-family: 'IBM Plex Serif', serif !important;
        font-size: 24px !important;
        line-height: 37px !important;
        margin-bottom: 15px;
      }
    }

    &__footer {
      align-items: center;
      display: flex;
      flex-direction: row;
      margin-top: 20px;

      &__button {
        display: flex !important;
        align-items: center !important;
        margin-right: 30px !important;
      }

      &__checkbox {
        label {
          color: #fff !important;
          font-weight: 400;
          font-size: 15px !important;
          line-height: 19px;
        }

        label::before {
          background: transparent !important;
          border: solid 1px #fff !important;
          border-radius: 0 !important;
        }

        label::after {
          color: #fff !important;
        }
      }
    }
  }
}
