@import 'assets/scss/Variables.scss';

.edit-columns {
  z-index: 8;
  float: right;
  position: relative;

  &__toggle-btn {
    border: 1px solid rgb(3 82 45 / 30%) !important;
    background-color: $primary-bg-color !important;
    border-radius: 0;
    height: 40px;
    font-size: 12px;
    font-weight: 400 !important;
    color: $primary-color !important;
    float: right;
    z-index: 8;

    i {
      border: 0;
      background-color: $primary-bg-color !important;
      height: 14px !important;
      top: 10px !important;
    }
  }

  &__menu {
    position: absolute !important;
    top: 15px;
    right: 3px;
    z-index: 7;

    .dropdown::before {
      content: '' !important;
    }

    .menu.transition.visible {
      height: max-content;
    }
  }

  &__combo {
    z-index: 7;

    &__control {
      width: 500px;
      border: 1px solid rgb(3 82 45 / 30%);

      &--is-focused,
      &--menu-is-open {
        border: 1px solid $primary-color !important;
        box-shadow: 0 0 0 1px $primary-color !important;
      }
    }

    &__placeholder {
      color: $primary-color;
    }

    &__menu {
      position: relative !important;
    }

    &__option--is-selected {
      label {
        color: white !important;
      }
    }

    &__option--is-disabled {
      background-color: $grey-bg-color !important;

      label {
        color: black !important;
      }
    }
  }
}
