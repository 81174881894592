.column-actions {
  align-items: center;
  display: flex;
  justify-content: center;

  .delete-icon {
    margin-left: 23px;

    :hover {
      cursor: pointer;
    }
  }

  .upload-icon {
    :hover {
      cursor: pointer;
    }
  }
}
