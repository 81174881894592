@import './Variables.scss';

:root {
  --toastify-color-info: #{$info-bg-color};
  --toastify-color-success: #{$success-bg-color};
  --toastify-color-warning: #{$warn-bg-color};
  --toastify-color-error: #{$error-bg-color};
  --toastify-text-color-info: #{$info-text-color};
  --toastify-text-color-success: #{$success-text-color};
  --toastify-text-color-warning: #{$warn-text-color};
  --toastify-text-color-error: #{$error-text-color};
}

/* overriding the react-toastify class */
/* stylelint-disable */
.Toastify__toast-container {
  width: 560px;

  .Toastify__toast {
    border-radius: 8px;
    padding: 10px;
    padding-right: 20px;
    width: 560px;

    .Toastify__toast-body {
      max-width: 420px;
    }

    .Toastify__close-icon {
      display: flex;
      align-self: center;
    }
  }
}
/* stylelint-enable */
