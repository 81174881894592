@import 'assets/scss/Mixins.scss';
@import 'assets/scss/Variables.scss';

.add-new-user {
  &__content {
    &__form {
      &__input-error {
        color: #da6868;
        padding: 10px 0;
      }

      .form-text-input-username {
        width: 100%;
        min-height: 60px;
        display: flex;
        flex-direction: column;
        background: $primary-bg-color;
        border: 2px solid #d7d7d7;
        padding: 3px;

        &__label {
          font-style: normal;
          font-weight: 400;
          font-size: 13px;
          color: $text-color;
          opacity: 0.7;
          margin-left: 9px;
          margin-bottom: 5px;
        }

        &__height {
          height: 25px;
        }

        input {
          margin-left: 9px !important;
          color: $text-color !important;
        }
      }
    }
  }

  &__footer {
    background: $primary-bg-color !important;
    border-top: 0 !important;

    &__cancel-btn {
      @include height-width-bg(48px, 123px, #ffffff !important);
      @include icon-style(3px !important);

      border: 2px solid $primary-color !important;
      border-radius: unset !important;
      box-sizing: border-box;
      color: $primary-color !important;
      font-weight: 300 !important;
      padding: 11px 0 !important;

      i {
        background: unset;
        height: 15px !important;
      }
    }

    &__submit {
      @include height-width-bg(48px, 160px, $primary-color !important);

      border-radius: unset !important;
      padding: 11px 21px !important;
      font-weight: 300 !important;
    }
  }
}
