@import 'assets/scss/Variables.scss';

.under-process {
  height: 100%;
  padding: 20px;
  padding-top: 0;

  &__header {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 10px 0;

    &__duplicates {
      color: $red-color;
      font-size: 15px;
      font-weight: 400;
    }
  }

  &__main {
    height: calc(100% - 95px);

    &--no-records {
      align-items: center !important;
      display: flex !important;
      justify-content: center;
    }

    .semantic-table-section {
      border: 0 !important;
      box-shadow: none !important;
      padding: 0 !important;
      font-size: 12px;
      height: 100%;

      .ui.grid {
        height: 100% !important;

        #upload-data-table-row {
          height: 100%;
          padding-bottom: 0 !important;

          .column {
            overflow: auto;
            height: 100%;
            min-height: 200px;
            padding-right: 0;

            #upload-data-table {
              font-size: 12px;
              width: max-content;
              min-width: 100%;
              border: 1px solid #d4d4d5;

              tr {
                td {
                  font-size: 12px !important;
                  vertical-align: middle !important;
                  border: 1px solid #d4d4d5;
                  padding: 7px 5px;

                  .highlight-text {
                    background-color: $text-highlight-color;
                    font-weight: 600;
                  }

                  .text-more-less {
                    color: $primary-color;
                    font-weight: 600;
                  }
                }

                &.row-spacer td {
                  border-top: 1px solid $alert-bg-color !important;
                }

                &:not(.negative) {
                  td {
                    .case-code {
                      color: $primary-color;
                      font-weight: 700;
                    }
                  }
                }

                &.negative {
                  td {
                    border: 1px solid $alert-bg-color !important;
                    border-left-width: 0 !important;
                    border-bottom-width: 0 !important;
                    color: $text-color !important;
                    font-size: 12px !important;
                    padding: 7px 5px;

                    &:first-child {
                      color: $red-color !important;
                      border-left-width: 1px !important;
                    }

                    .case-code {
                      display: flex;
                      font-weight: 700;
                    }
                  }

                  &:last-child td {
                    border-bottom-width: 1px !important;
                  }
                }

                th {
                  font-size: 12px !important;
                  vertical-align: middle !important;
                  background-color: $grey-bg-color !important;
                  position: sticky !important;
                  top: -2px !important;
                  z-index: 5 !important;
                  border-left: 0;
                  border-radius: 0 !important;
                  padding: 7px 5px;
                }
              }
            }
          }
        }
      }
    }
  }
}
