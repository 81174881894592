// @import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Serif:wght@600&display=swap');
@import 'assets/scss/Variables.scss';
@import 'assets/scss/Mixins.scss';
@import 'assets/scss/Button.scss';
@import 'assets/scss/Utilities.scss';
@import 'assets/scss/Toast.scss';

@font-face {
  font-family: 'Henderson Sans';
  src: url('./assets/fonts/BCGHenSansRegular.ttf');
}

.body {
  font-family: 'Henderson Sans', sans-serif;
  background-color: #e5e5e5 !important;
}

.main-container {
  display: flex;
  flex-direction: row;
  align-items: stretch;

  .left-container {
    width: 115px;
  }

  .right-container {
    align-self: flex-start;
    background-color: $primary-bg-color !important;
    height: 97%;
    margin: 10px;
  }
}

// modal dimmer should come above the loaders
.ui.modals.dimmer {
  z-index: 1100 !important;
}
