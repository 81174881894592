/* Mixins */

@mixin text-style($fs, $fw) {
  font-size: $fs;
  font-weight: $fw;
  font-family: 'henderson sans', sans-serif;
  font-style: normal;
}

@mixin height-width-bg($ht, $wt, $bg) {
  height: $ht;
  width: $wt;
  background-color: $bg;
}

@mixin icon-style($top: 3px, $height: 15px, $width: 15px) {
  i {
    top: $top;
    background: none;
    position: relative;

    img {
      width: $height;
      height: $width;
    }
  }
}

@mixin sortable-table($id: '#ppc-data-table') {
  .semantic-table-section {
    border: 0 !important;
    box-shadow: none !important;
    padding: 0 !important;
    font-size: 12px;
    height: 100%;

    .ui.grid {
      height: 100% !important;

      #{$id}-row {
        height: 98%;
        padding-bottom: 0 !important;

        .column {
          height: 100%;
          min-height: 200px;
          overflow: auto;
          padding-right: 0;

          #{$id} {
            font-size: 12px;
            width: max-content;
            min-width: 100%;

            tr {
              td {
                font-size: 12px !important;
                vertical-align: middle !important;
                padding: 7px 5px;

                .case-code {
                  color: $text-color;
                }

                .ui.checked {
                  label::after {
                    background-color: $primary-color;
                    color: $primary-bg-color;
                    font-size: 10px;
                  }
                }

                .highlight-text {
                  background-color: $text-highlight-color;
                  font-weight: 600;
                  color: inherit;
                }

                .text-more-less {
                  color: $primary-color;
                  font-weight: 600;
                }
              }

              th {
                font-size: 12px !important;
                vertical-align: middle !important;
                background-color: $grey-bg-color !important;
                position: sticky !important;
                top: -2px !important;
                z-index: 5 !important;
                border-left: 0;
                border-radius: 0 !important;
                padding: 7px 5px;

                &.ascending::after {
                  content: '';
                  width: 12px;
                  height: 12px;
                  background-image: url('../../assets/images/icons/ascending.svg');
                  background-size: cover;
                }

                &.descending::after {
                  content: '';
                  width: 12px;
                  height: 12px;
                  background-image: url('../../assets/images/icons/descending.svg');
                  background-size: cover;
                }
              }
            }

            tbody {
              tr {
                &:hover,
                &.row-selected,
                td:hover {
                  background: #e4f3e4 !important;
                  box-sizing: border-box;
                  box-shadow: 3px 4px 14px rgb(3 82 45 / 20%);
                  color: $primary-color !important;

                  .case-code {
                    font-weight: 700;
                    color: $primary-color;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
