@import 'assets/scss/Variables.scss';
@import 'assets/scss/Mixins.scss';

.edit-modal {
  &__header-content {
    height: 60px;

    &__header-detail {
      display: flex;
      justify-content: flex-start;

      &__header-text {
        margin-left: 10px;
        font-size: 15px;
        color: $text-color;
      }
    }
  }

  &__footer {
    background: $primary-bg-color !important;
    border-top: 0 !important;

    &__cancel-btn {
      @include height-width-bg(48px, 123px, $primary-bg-color !important);
      @include icon-style(3px !important);

      border: 2px solid $primary-color !important;
      box-sizing: border-box;
      color: $primary-color !important;
      border-radius: unset !important;
      padding-right: 0 !important;
      padding-left: 0 !important;
      font-weight: 300 !important;

      i {
        background: unset;
        height: 15px !important;
      }
    }

    &__submit {
      @include height-width-bg(48px, 130px, $primary-color !important);

      border-radius: unset !important;
      padding: 11px 21px !important;
      font-weight: 300 !important;
    }
  }
}
