@import 'assets/scss/Variables.scss';
@import 'assets/scss/Mixins.scss';

.search-page {
  height: 100%;
  padding: 15px;
  padding-top: 0;

  &__header {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 15px 0;

    &__records {
      display: flex;
      flex-direction: column;

      &__searched {
        font-weight: 600;
        color: $text-color;
        font-size: 15px;
      }

      &__total {
        font-weight: 300;
        font-size: 12px;
        color: $dark-grey-color;
      }

      &__message {
        margin: 5px 0 0 !important;
        padding: 5px !important;
      }
    }

    &__actions {
      display: flex;

      &__action {
        margin-right: 10px !important;

        :last-child {
          margin-right: 0 !important;
        }
      }

      .download-result {
        height: 40px;
      }

      .save-my-list {
        height: 40px;
      }
    }
  }

  &__main {
    height: calc(100% - 95px);

    &--no-records {
      align-items: center !important;
      display: flex !important;
      justify-content: center;
    }

    @include sortable-table;
  }
}
