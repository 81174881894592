@import 'assets/scss/Variables.scss';

.case-information {
  width: calc(100% - 240px);

  &__grid {
    margin-bottom: 0 !important;
    margin-top: 0 !important;

    &__row {
      padding: 0 !important;
    }
  }

  &__header {
    display: flex;
    flex-direction: row;

    &__popout {
      margin: 5px;
    }

    &__case-id {
      font-weight: 700;
      font-size: 12px;
      color: #197a56;
    }

    &__underline {
      cursor: pointer;
      text-decoration-line: underline;
    }

    &__case-type {
      margin-left: 6px;
      font-weight: 700;
      font-size: 12px;
      color: #197a56;
    }
  }

  &__hr {
    width: 100%;
    opacity: 0.2;
    border: 1px solid #000;
    background-color: #000;
    margin-bottom: 4px;
    margin-top: 1px;
  }

  &__two-blocks-together {
    display: flex;

    &__left-block {
      display: flex;
      flex: 2;
      flex-direction: column;
    }

    &__right-block {
      flex: 4;
      margin-left: 20px;
    }
  }

  &__section-container {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  &__section-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    &__project-icon-name {
      display: flex;
      align-items: flex-end;

      &__project-name {
        font-style: normal;
        font-weight: 600;
        font-size: 15px;
        color: $text-color;
      }
    }

    &__edit {
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      margin-right: 15px;

      &__edit-icon {
        height: 15px;
        width: 15px;
      }

      &__edit-text {
        font-style: normal;
        margin-left: 5px;
        font-weight: 400;
        font-size: 12px;
        color: $primary-color;
      }
    }
  }

  &__project-description {
    font-style: normal;
    margin-top: 5px;
    font-weight: 400;
    font-size: 13px;
    color: $text-color;
    line-height: 14px;
    opacity: 0.7;

    &__more-less {
      color: #197a56;
      text-decoration-line: underline;
    }
  }

  &__project-name-container {
    background: $light-red-color;
    margin-top: 5px;
    margin-bottom: 9px;
    padding: 4px 10px;
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    flex: 1;

    &__block {
      border-right: solid 2px #a5a5a5;
      display: flex;
      flex: 1;
      flex-direction: column;
      margin-left: 4px;
      padding: 0 10px;
    }

    &__block:first-of-type {
      padding-left: 0;
    }

    &__block:last-of-type {
      border: none;
    }

    &__upper-text {
      font-weight: 600;
      color: #197a56;
    }

    &__lower-text {
      font-size: 13px;
      line-height: 16px;
      color: $text-color;
      opacity: 0.7;

      &__more-less {
        color: #197a56;
        text-decoration-line: underline;
      }
    }
  }
}
