.operator-suggestions {
  background: #fff;
  border: solid 1px;
  position: absolute;
  z-index: 2;
  top: 40px;
  left: 80px;

  > .list {
    > .item {
      padding: 10px 15px !important;
      cursor: pointer;

      &:hover {
        background: #b1e6fd;
      }
    }
  }
}
