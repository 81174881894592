@import 'assets/scss/Variables.scss';
@import 'assets/scss/Mixins.scss';

.user-management {
  height: 100%;
  padding: 15px;

  &__header {
    display: flex;
    justify-content: space-between;
    position: relative;

    &__actions {
      display: flex;

      &__action {
        margin-right: 10px !important;
      }

      .save {
        align-items: center;
        border-radius: 0 !important;
        color: #062a11 !important;
        display: flex !important;
        font-weight: 300 !important;
        padding: 11px 21px;

        i {
          height: 18px !important;
          margin-right: 10px !important;
        }
      }

      :last-child {
        margin-right: 0 !important;
      }
    }

    &__title {
      align-items: center;
      display: flex;
      font-size: 15px;
      font-weight: 600;
    }
  }

  &__main {
    height: calc(100% - 80px);

    .semantic-table-section {
      border: 0 !important;
      box-shadow: none !important;
      padding: 0 !important;
      font-size: 12px;
      height: 100%;

      .ui.grid {
        height: 100% !important;
        margin: 0 !important;

        #users-table-row {
          height: 98%;
          padding-bottom: 0 !important;

          .column {
            height: 100%;
            min-height: 200px;
            overflow: auto;
            padding-left: 0;
            padding-right: 0;

            #users-table {
              font-size: 12px;
              width: max-content;
              min-width: 100%;

              tr {
                td {
                  font-size: 12px !important;
                  vertical-align: middle !important;

                  &.current-user-role {
                    overflow: visible !important;
                  }

                  .ui.checked {
                    label::after {
                      background-color: $primary-color;
                      color: $primary-bg-color;
                      font-size: 10px;
                    }
                  }

                  .highlight-text {
                    background-color: $text-highlight-color;
                    font-weight: 600;
                    color: inherit;
                  }

                  .text-more-less {
                    color: $primary-color;
                    font-weight: 600;
                  }
                }

                th {
                  font-size: 12px !important;
                  vertical-align: middle !important;
                  background-color: $grey-bg-color !important;
                  position: sticky !important;
                  top: -2px !important;
                  z-index: 5 !important;
                  border-left: 0;
                  border-radius: 0 !important;
                  padding-top: 7px;
                  padding-bottom: 7px;

                  &.ascending::after {
                    content: '';
                    width: 12px;
                    height: 12px;
                    background-image: url('../../assets/images/icons/ascending.svg');
                    background-size: cover;
                  }

                  &.descending::after {
                    content: '';
                    width: 12px;
                    height: 12px;
                    background-image: url('../../assets/images/icons/descending.svg');
                    background-size: cover;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
