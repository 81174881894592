@import 'assets/scss/Variables.scss';

.header-filter {
  position: sticky;
  left: 0;
  top: 0;
  height: 75px;
  width: 100%;
  background-color: $primary-bg-color;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 15px;

  // for making radio button border black color
  .ui.radio.checkbox .box::before,
  .ui.radio.checkbox label::before {
    border: 2px solid black;
  }

  .ui.radio.checkbox input:checked ~ .box::before,
  .ui.radio.checkbox input:checked ~ label::before {
    border: 2px solid black;
  }

  &__radio-group {
    display: flex;
    flex-basis: 9%;
    flex-direction: column;

    &__radio {
      font-size: 12px !important;
    }
  }

  &__input {
    flex-basis: 10%;

    input {
      border-radius: 0 !important;
      font-size: 12px !important;
      min-height: 38px;
      width: 100%;
    }
  }

  &__filter-dropdown {
    font-size: 12px !important;

    &--industry {
      flex-basis: 17%;
    }

    &--case-type {
      flex-basis: 12%;
    }

    &--topic {
      flex-basis: 10%;
    }

    &--region {
      flex-basis: 10%;
    }

    &--year {
      flex-basis: 10%;
    }

    // making dropdown down-arrow black
    &__indicator {
      color: #6c6c6c !important;
      padding: 1px !important;
    }

    // removing vertical bar from dropdown
    &__indicator-separator {
      display: none;
    }

    // to remove border-radius from dropdown fields
    &__control {
      border-radius: 0 !important;
    }

    &__placeholder {
      color: hsl(0deg 5% 79%) !important;
    }

    &__value-container {
      justify-content: space-between;
      padding: 2px 2px 2px 8px !important;

      &__selected-value {
        display: flex;
        justify-content: space-between;
        position: absolute;
        right: 0;
        width: 20px;

        &__count {
          background: $primary-color;
          border-radius: 50%;
          color: #fff;
          font-size: 12px;
          font-weight: 600;
          min-height: 12px;
          min-width: 20px;
          text-align: center;
        }
      }
    }
  }

  .header-filter {
    &__filter-dropdown {
      &__clear-indicator {
        cursor: pointer;
      }
    }
  }

  .go-btn {
    align-items: center;
    display: flex !important;
    justify-content: center;
    width: 38px;
    height: 38px;
    background: $primary-color !important;
    border: 1px solid $primary-color !important;
    border-radius: 0 !important;

    .go-text {
      font-family: 'Henderson Sans', sans-serif;
      color: $primary-bg-color;
    }
  }
}
