.no-data {
  align-items: center;
  display: flex;
  justify-content: center;

  img {
    width: auto;
    height: 200px;
  }
}
