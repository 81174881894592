@import 'assets/scss/Variables.scss';

.form-text-input {
  width: 100%;
  min-height: 60px;
  display: flex;
  flex-direction: column;
  background: $primary-bg-color;
  border: 2px solid #d7d7d7;
  padding: 3px;

  &__label {
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    color: $text-color;
    opacity: 0.7;
    margin-left: 9px;
    margin-bottom: 5px;
  }

  &__height {
    height: 25px;
  }

  input {
    margin-left: 9px !important;
    color: $text-color !important;
  }
}
