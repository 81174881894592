@import 'assets/scss/Mixins.scss';
@import 'assets/scss/Variables.scss';

.investible-dropdown {
  .menu.transition.visible {
    position: relative !important;
    z-index: 7;
    left: 0 !important;
    min-width: 120px;
    padding: 10px;
    background: #fff;
    box-shadow: 0 0 25px rgb(0 0 0 / 20%);

    &::after {
      display: none;
    }
  }

  .menu {
    .item {
      display: flex !important;
      flex-direction: column;

      &.disabled {
        opacity: 1 !important;
      }

      .caption {
        font-size: 10px;
        color: $dark-grey-color;
      }
    }
  }

  &__wrapper {
    position: absolute;
    left: 110px !important;
    z-index: 1010;

    &::after {
      display: block;
      position: absolute;
      pointer-events: none;
      content: '';
      visibility: visible;
      transform: rotate(45deg);
      width: 0.5em;
      height: 0.5em;
      box-shadow: -1px 0 0 0 rgb(34 36 38 / 15%);
      background: #fff;
      z-index: 7;
      top: 30px;
      left: 10px;
    }
  }
}
