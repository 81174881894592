@import 'assets/scss/Variables.scss';

.form-text-area {
  display: flex;
  flex-direction: column;
  background: $primary-bg-color;
  padding: 3px;
  border: 2px solid #d7d7d7;

  textarea:focus {
    outline: none !important;
    box-shadow: 0 0 10px #fff;
  }

  &__label {
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    color: $text-color;
    opacity: 0.7;
    margin-left: 9px;
    margin-bottom: 3px;
  }

  &__items {
    border-color: $primary-bg-color;
    font-style: normal;
    font-size: 14px;
    color: #111;
    margin-left: 8px;
  }
}
