$direction: ('top', 'right', 'bottom', 'left');
$length: (0, 5, 10, 15, 30, 45, 50);

/**
* Generates classes like:
* .padding-0, .padding-5, .padding-10 etc, and
* .margin-0, .margin-5, .margin-10 etc
*/
@each $len in $length {
  .padding-#{$len} {
    padding: #{$len}px;
  }

  .margin-#{$len} {
    margin: #{$len}px;
  }
}

/**
* Generates classes like:
* .padding-left-0, .padding-botton-5, .padding-top-10 etc, and
* .margin-bottom-0, .margin-top-5, .margin-left-10 etc
*/
@each $dir in $direction {
  @each $len in $length {
    .padding-#{$dir}-#{$len} {
      padding-#{$dir}: #{$len}px;
    }

    .margin-#{$dir}-#{$len} {
      margin-#{$dir}: #{$len}px;
    }
  }
}

.hidden {
  display: none !important;
}
